<template>
	<div>
		<div
			class="codejar-editor language-json"
			ref="codejareditor"
			:style="readOnly ? 'pointer-events: none' : ''"
		/>
	</div>
</template>

<script>
import { CodeJar } from "codejar";
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);
hljs.configure({ ignoreUnescapedHTML: true });
import "highlight.js/styles/night-owl.css";

export default {
	name: "WsCodeEditor",

	props: {
		modelValue: {
			type: null,
			default: null
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},

	watch: {
		modelValue: function (_newValue) {
			if (_newValue !== this.text) {
				this.updateTextInEditor();
			}
		}
	},

	data() {
		return {
			editor: null,
			text: this.modelValue
		};
	},
	emits: ["update:modelValue"],
	mounted() {
		this.editor = CodeJar(this.$refs.codejareditor, hljs.highlightElement);
		this.editor.onUpdate((code) => {
			this.text = code;
			this.$emit("update:modelValue", code);
		});
		this.updateTextInEditor();
	},

	methods: {
		updateTextInEditor() {
			setTimeout(() => {
				if (!this.modelValue && !this.editor) {
					this.updateTextInEditor();
				}
				this.text = this.modelValue;
				this.editor.updateCode(
					typeof this.modelValue === "string"
						? this.modelValue
						: JSON.stringify(this.modelValue)
				);
			}, 100);
		}
	}
};
</script>

<style lang="scss" scoped>
$box-shadow-code-editor:
	0 2px 2px 0 rgba($black, 0.14),
	0 1px 5px 0 rgba($black, 0.12),
	0 3px 1px -2px rgba($black, 0.2);

.codejar-editor {
	border-radius: 6px;
	box-shadow: $box-shadow-code-editor;
	font-size: 18px;
	font-weight: 400;
	height: 340px;
	letter-spacing: normal;
	line-height: 20px;
	padding: 10px;
	tab-size: 4;
}
</style>
